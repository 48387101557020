$serif-font: "Libre Baskerville", serif;
$sans-serif-font: "Karla", sans-serif;
$gray: #464646;
$lightgreen: #97af9d;
$base: #89b196;
$base-dark: #7fa58b;
$base-light: #b2d3bd;

@import "./mixins";

html,
body {
  width: 100%;
}

body {
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: $sans-serif-font;
  color: $gray;
  text-align: center;
  margin: 0;
}

main {
  min-height: 100vh;
}

.hide {
  display: none !important;
}

.no-user-select {
  user-select: none;
}

.page-header {
  margin-bottom: 5em;
}

.page-title {
  font-family: $serif-font;
  font-size: 2.5em;
  letter-spacing: 0.05em;
  margin: 0;
}

.page-subtitle {
  font-weight: 400;
  font-size: 1.2em;
  margin-top: 0.3em;
}

h3 {
  font-family: $serif-font;
  font-size: 1.8em;
  margin: 0;
  letter-spacing: 0.03em;
}

h4 {
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0.2em;
}

h5 {
  font-size: 24px;
  margin: 10px 0;
  font-weight: 500;
}

p {
  font-size: 1.1em;
  line-height: 1.3em;
  max-width: 650px;
}

button {
  font-family: $sans-serif-font;
  font-size: 0.9em;
  padding: 0.8em 1.5em;
  background-color: $base;
  border: 2px solid $base-dark;
  color: white;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  border-radius: 10px;
  transition: 0.3s ease;

  &:hover {
    cursor: pointer;
    background-color: white;
    color: $base;
  }
}

.btn-group {
  @include flexbox();
  gap: 0.5em;
  margin-top: 2em;
}

.btn-group-vert {
  @include mq(xs) {
    flex-direction: column;
  }
}

.btn-sm {
  padding: 0.4em 0.8em;
}
.btn-md {
  padding: 0.6em 1.2em;
}

a {
  color: $gray;
  text-decoration: none;
}

a,
.bi {
  transition: 0.25s;
}

a:hover,
.bi:hover {
  color: $base;
  cursor: pointer;
}

.return-to-top {
  position: fixed;
  z-index: 2;

  .bi-arrow-up-circle {
    position: absolute;
    font-size: 2.5em;

    @include mq(md) {
      font-size: 3em;
    }
  }
}

.no-scroll {
  overflow-y: hidden;
}



@import "./navbar";
@import "./photo";
@import "./home";
@import "./footer";
