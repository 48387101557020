nav {
  position: fixed;
  width: 100%;
  padding: 15px 25px;
  box-sizing: border-box;
  z-index: 100;
  background-color: rgba(255, 255, 255);
}

.navbar {
  @include flexbox(row, space-between);
  max-width: 1300px;
  margin: 0 auto;
}

.nav-logo {
  font-weight: 500;
  font-size: 28px;
  user-select: none;
  @include flexbox();
  gap: 0.5em;
  z-index: 101;
  letter-spacing: 0.08em;

  .logo-border {
    width: 40px;
    height: 40px;
    border: 2px solid $gray;
    border-radius: 100px;
  }

  img {
    width: 20px;
    padding: 8px;
  }

  // &.page-title {
  //   padding: 0;
  // }
}

@media (max-width: 450px) {
  nav {
    padding: 15px;
  }
  .nav-logo {
    font-size: 24px;
    gap: 0.2em;

    .logo-border {
      scale: 0.8;

    }
  }

  .menu-close-icon {
    scale: 0.2;
  }
}

.nav-links {
  @include flexbox();

  a, .bi {
  margin: 0 16px;
  font-size: 18px;
  border-bottom: 1px solid #00000000;
  padding: 5px;
  }


  .active {
    border-color: $base;
  }
}

.divider {
  display: inline-block;
  width: 1.5px;
  height: 25px;
  background-color: $gray;
  margin: 0 10px;
}

.mobile-nav-links {
  @include fullscreen();

  a {
    font-size: 32px;
    font-weight: 400;
  }

  .bi {
    font-size: 24px;
  }

  // :last-child {
  //   margin-top: 10px;
  // }

  .divider {
    margin: 20px;
    height: 40px;
  }
}


.links-motion-wrapper {
  @include full-wh();
  @include flexbox(column, flex-start);
  background-color: #fff;
  padding-top: 250px;
}


.menu-close-icon {
  width: 40px;
  height: 40px;
  scale: 0.35;

  &:hover span {
    background-color: $base;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    cursor: pointer;
  }
  
  span {
    display: block;
    width: 100%;
    border-radius: 3px;
    height: 8px;
    background: $gray;
    transition: all .3s;
    position: relative;
  }
  
  span + span {
    margin-top: 14px;
  }
  
  .active span:nth-child(1) {
    animation: ease .7s top forwards;
  }
  
  .not-active span:nth-child(1) {
    animation: ease .7s top-2 forwards;
  }
  
  .active span:nth-child(2) {
    animation: ease .7s scaled forwards;
  }
  
  .not-active span:nth-child(2) {
    animation: ease .7s scaled-2 forwards;
  }
  
  .active span:nth-child(3) {
    animation: ease .7s bottom forwards;
  }
  
  .not-active span:nth-child(3) {
    animation: ease .7s bottom-2 forwards;
  }
  
  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 22px;
      transform: rotate(0);
    }
    100% {
      top: 22px;
      transform: rotate(45deg);
    }
  }
  
  @keyframes top-2 {
    0% {
      top: 22px;
      transform: rotate(45deg);
    }
    50% {
      top: 22px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }
  
  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 22px;
      transform: rotate(135deg);
    }
  }
  
  @keyframes bottom-2 {
    0% {
      bottom: 22px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }
  
  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }
  
  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

