html,
body {
  width: 100%;
}

body {
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: "Karla", sans-serif;
  color: #464646;
  text-align: center;
  margin: 0;
}

main {
  min-height: 100vh;
}

.hide {
  display: none !important;
}

.no-user-select {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.page-header {
  margin-bottom: 5em;
}

.page-title {
  font-family: "Libre Baskerville", serif;
  font-size: 2.5em;
  letter-spacing: 0.05em;
  margin: 0;
}

.page-subtitle {
  font-weight: 400;
  font-size: 1.2em;
  margin-top: 0.3em;
}

h3 {
  font-family: "Libre Baskerville", serif;
  font-size: 1.8em;
  margin: 0;
  letter-spacing: 0.03em;
}

h4 {
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0.2em;
}

h5 {
  font-size: 24px;
  margin: 10px 0;
  font-weight: 500;
}

p {
  font-size: 1.1em;
  line-height: 1.3em;
  max-width: 650px;
}

button {
  font-family: "Karla", sans-serif;
  font-size: 0.9em;
  padding: 0.8em 1.5em;
  background-color: #89b196;
  border: 2px solid #7fa58b;
  color: white;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  border-radius: 10px;
  transition: 0.3s ease;
}
button:hover {
  cursor: pointer;
  background-color: white;
  color: #89b196;
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin-top: 2em;
}

@media only screen and (max-width: 600px) {
  .btn-group-vert {
    flex-direction: column;
  }
}

.btn-sm {
  padding: 0.4em 0.8em;
}

.btn-md {
  padding: 0.6em 1.2em;
}

a {
  color: #464646;
  text-decoration: none;
}

a,
.bi {
  transition: 0.25s;
}

a:hover,
.bi:hover {
  color: #89b196;
  cursor: pointer;
}

.return-to-top {
  position: fixed;
  z-index: 2;
}
.return-to-top .bi-arrow-up-circle {
  position: absolute;
  font-size: 2.5em;
}
@media only screen and (min-width: 900px) {
  .return-to-top .bi-arrow-up-circle {
    font-size: 3em;
  }
}

.no-scroll {
  overflow-y: hidden;
}

nav {
  position: fixed;
  width: 100%;
  padding: 15px 25px;
  box-sizing: border-box;
  z-index: 100;
  background-color: rgb(255, 255, 255);
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
}

.nav-logo {
  font-weight: 500;
  font-size: 28px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  z-index: 101;
  letter-spacing: 0.08em;
}
.nav-logo .logo-border {
  width: 40px;
  height: 40px;
  border: 2px solid #464646;
  border-radius: 100px;
}
.nav-logo img {
  width: 20px;
  padding: 8px;
}

@media (max-width: 450px) {
  nav {
    padding: 15px;
  }
  .nav-logo {
    font-size: 24px;
    gap: 0.2em;
  }
  .nav-logo .logo-border {
    scale: 0.8;
  }
  .menu-close-icon {
    scale: 0.2;
  }
}
.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nav-links a, .nav-links .bi {
  margin: 0 16px;
  font-size: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  padding: 5px;
}
.nav-links .active {
  border-color: #89b196;
}

.divider {
  display: inline-block;
  width: 1.5px;
  height: 25px;
  background-color: #464646;
  margin: 0 10px;
}

.mobile-nav-links {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mobile-nav-links a {
  font-size: 32px;
  font-weight: 400;
}
.mobile-nav-links .bi {
  font-size: 24px;
}
.mobile-nav-links .divider {
  margin: 20px;
  height: 40px;
}

.links-motion-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  padding-top: 250px;
}

.menu-close-icon {
  width: 40px;
  height: 40px;
  scale: 0.35;
}
.menu-close-icon:hover span {
  background-color: #89b196;
}
.menu-close-icon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  cursor: pointer;
}
.menu-close-icon span {
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 8px;
  background: #464646;
  transition: all 0.3s;
  position: relative;
}
.menu-close-icon span + span {
  margin-top: 14px;
}
.menu-close-icon .active span:nth-child(1) {
  animation: ease 0.7s top forwards;
}
.menu-close-icon .not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}
.menu-close-icon .active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}
.menu-close-icon .not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
}
.menu-close-icon .active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}
.menu-close-icon .not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}
@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 22px;
    transform: rotate(0);
  }
  100% {
    top: 22px;
    transform: rotate(45deg);
  }
}
@keyframes top-2 {
  0% {
    top: 22px;
    transform: rotate(45deg);
  }
  50% {
    top: 22px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}
@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 22px;
    transform: rotate(135deg);
  }
}
@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}
@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.parallax-image-header {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 5em;
  position: relative;
}
.parallax-image-header .overlay {
  background-color: rgba(0, 0, 0, 0.2078431373);
  width: 100%;
  height: 100%;
  position: absolute;
}
.parallax-image-header .page-header {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5019607843);
}
@media only screen and (max-width: 600px) {
  .parallax-image-header .page-header .page-title {
    font-size: 2em;
  }
  .parallax-image-header .page-header .page-subtitle {
    font-size: 1em;
    margin-top: 0;
  }
}
@media only screen and (min-width: 900px) {
  .parallax-image-header .page-header {
    top: 50%;
  }
}

.photo-category-page .react-photo-gallery--gallery {
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
}
@media only screen and (min-width: 900px) {
  .photo-category-page .react-photo-gallery--gallery {
    width: 70%;
  }
}

.home .bi-geo-alt {
  margin-right: 15px;
}
.home .parallax-image-header .page-subtitle {
  margin-top: 0;
  letter-spacing: 0.05em;
}
.home .home-divider {
  margin: 50px auto;
  width: 200px;
  height: 2px;
  background-color: #89b196;
}

.about {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
}
.about img {
  width: 80vw;
  max-width: 400px;
}
.about section {
  max-width: 450px;
}
.about section .page-title {
  font-size: 30px;
  margin-bottom: 1em;
}
@media only screen and (min-width: 900px) {
  .about {
    flex-direction: row;
    text-align: left;
    padding: 50px;
  }
  .about p {
    text-indent: 1.5em;
  }
  .about img {
    order: -1;
  }
  .about .text {
    margin-left: 20px;
  }
}

.categories-wrapper {
  gap: 80px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 900px) {
  .categories-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1200px) {
  .categories-wrapper {
    max-width: unset;
  }
}
.categories-wrapper .category-card .image-wrapper {
  overflow: hidden;
  width: 75vw;
  height: 100vw;
  max-width: 375px;
  max-height: 500px;
}
@media only screen and (min-width: 900px) {
  .categories-wrapper .category-card .image-wrapper {
    max-width: 270px;
    max-height: 360px;
  }
}
@media only screen and (min-width: 1200px) {
  .categories-wrapper .category-card .image-wrapper {
    max-width: 225px;
    max-height: 300px;
  }
}
@media only screen and (min-width: 1400px) {
  .categories-wrapper .category-card .image-wrapper {
    max-width: 270px;
    max-height: 360px;
  }
}
.categories-wrapper .category-card .image {
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: 0.3s ease;
}
.categories-wrapper .category-card:hover .image {
  scale: 1.1;
  cursor: pointer;
}

#footer {
  background-color: #89b196;
  padding: 10px;
  color: #fff;
  margin-top: 150px;
  font-size: 14px;
}
@media only screen and (max-width: 600px) {
  #footer {
    font-size: 12px;
  }
}/*# sourceMappingURL=App.css.map */