.parallax-image-header {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 5em;
  position: relative;

  .overlay {
    background-color: #00000035;
    @include full-wh();
    position: absolute;
  }

  .page-header {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 0 0 10px #00000080;
    

    @include mq(xs) {
      .page-title {
        font-size: 2em;
      }
      .page-subtitle {
        font-size: 1em;
        margin-top: 0;
      }
    }

    @include mq(md) {
      top: 50%;
    }
  }
}


.photo-category-page .react-photo-gallery--gallery {
  max-width: 1000px;
  @include center-width(90%);
  @include mq(md) {
    width: 70%;
  }
}
