.home {
  .bi-geo-alt {
    margin-right: 15px;
  }

  .parallax-image-header {
    .page-subtitle {
      margin-top: 0;
      letter-spacing: 0.05em;
    }
  }


  .home-divider {
    margin: 50px auto;
    width: 200px;
    height: 2px;
    background-color: $base;
  }
}

.about {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 30px;
  @include flexbox(column, center, center);
  gap: 2em;

  img {
    width: 80vw;
    max-width: 400px;
  }

  section {
    max-width: 450px;

    .page-title {
      font-size: 30px;
      margin-bottom: 1em;
    }
  }

  @include mq(md) {
    flex-direction: row;
    text-align: left;
    padding: 50px;

    p {
      text-indent: 1.5em;
    }

    img {
      order: -1;
    }

    .text {
      margin-left: 20px;
    }
  }
}

.categories-wrapper {
  // @include flexbox(column);
  // flex-wrap: wrap;
  gap: 80px;
  padding: 50px 0;
  // @include center-width(800px);

  @include flexbox(column);

  @include mq(md) {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 0 auto;
  }

  @include mq(lg) {
    max-width: unset;
  }
  

  .category-card {
    .image-wrapper {
      overflow: hidden;
      width: 75vw;
      height: 100vw;
      max-width: 375px;
      max-height: 500px;

      @include mq(md) {
        max-width: 270px;
        max-height: 360px;
      }

      @include mq(lg) {
        max-width: 225px;
        max-height: 300px;
      }

      @include mq(xl) {
        max-width: 270px;
        max-height: 360px;
      }
    }

    .image {
      @include full-wh();
      background-size: cover;
      transition: 0.3s ease;
    }

    &:hover .image  {
      scale: 1.1;
      cursor: pointer;
    }
  }
}
