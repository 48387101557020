@mixin flexbox-basic($direction: row) {
  display: flex;
  flex-direction: $direction;
}

@mixin flexbox($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin mq($breakpoint) {
  @if $breakpoint==xs {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint==sm {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }
  @if $breakpoint==md {
    @media only screen and (min-width: 900px) {
      @content;
    }
  }
  @if $breakpoint==lg {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint==xl {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  }
}

@mixin full-wh() {
  width: 100%;
  height: 100%;
}

@mixin fullscreen() {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@mixin center-width($width) {
  width: $width;
  margin: 0 auto;
}

@mixin res-width($xs, $sm, $md, $lg, $xl) {
  margin: 0 auto;
  @include mq(xs) {
    width: $xs;
  }
  @include mq(sm) {
    width: $sm;
  }
  @include mq(md) {
    width: $md;
  }
  @include mq(lg) {
    width: $lg;
  }
  @include mq(xl) {
    width: $xl;
  }
}

@mixin res-height($xs, $sm, $md, $lg, $xl) {
  @include mq(xs) {
    height: $xs;
  }
  @include mq(sm) {
    height: $sm;
  }
  @include mq(md) {
    height: $md;
  }
  @include mq(lg) {
    height: $lg;
  }
  @include mq(xl) {
    height: $xl;
  }
}
